<template>
  <v-row align="center" justify="space-around">
    <v-card flat min-width="600" max-width="1280" class="mx-14 mt-12 mb-8">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="success"
            fab
            dark
            x-small
            top
            left
            absolute
            class="ml-4 mt-6"
            @click="dialog = !dialog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn></template
        >
        <span>Add Goal</span>
      </v-tooltip>
      <v-timeline align-top dense class="mt-12">
        <v-timeline-item
          v-for="(yearItem, i) in clientGoalsSorted"
          :key="i"
          small
        >
          <v-row>
            <v-col cols="2" class="mt-n1 mb-4">
              <span
                :class="`title font-weight-bold primary--text`"
                v-text="yearItem.year"
              ></span>
            </v-col>
            <v-col>
              <div
                v-for="(dataItem, ii) in yearItem.data"
                :key="ii"
                class="mt-n1 mb-4"
              >
                <div v-if="dataItem.id != 0" class="py-0">
                  <span
                    v-if="
                      dataItem.type == 'Retirement' &&
                        dataItem.currentData.yearPerson1 == yearItem.year
                    "
                    :class="`title font-weight-light primary--text mb-2`"
                    v-text="dataItem.currentData.headlinePerson1"
                  ></span>
                  <span
                    v-else-if="
                      dataItem.type == 'Retirement' &&
                        dataItem.currentData.yearPerson2 == yearItem.year
                    "
                    :class="`title font-weight-light primary--text mb-2`"
                    v-text="dataItem.currentData.headlinePerson2"
                  ></span>

                  <span
                    v-else
                    :class="`title font-weight-light primary--text mb-2`"
                    v-text="dataItem.headline"
                  ></span>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="editItem(dataItem)"
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                        class="title ml-3 mb-2"
                        >mdi-pencil</v-icon
                      ></template
                    >
                    <span>Edit Goal</span>
                  </v-tooltip>

                  <v-tooltip v-if="dataItem.type != 'Retirement'" top>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        @click="deleteConfirm(dataItem)"
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                        class="title ml-2 mb-2"
                      >
                        mdi-delete
                      </v-icon></template
                    >
                    <span>Delete Goal</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="
                          !(
                            dataItem.type == 'Emergency-Fund' &&
                            dataItem.currentData.value === 0
                          )
                        "
                        @click="openPlan(dataItem)"
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                        class="title ml-3 mb-2"
                        >mdi-notebook-outline</v-icon
                      ></template
                    >
                    <span>Open Goal in Financial Plan</span>
                  </v-tooltip>

                  <div v-if="dataItem.type == 'Emergency-Fund'">
                    <h2
                      :class="`subtitle-2 font-weight-light primary--text`"
                      v-text="dataItem.currentData.descriptionEnhanced"
                    ></h2>
                  </div>
                  <div v-else-if="dataItem.type == 'Retirement'">
                    <h2
                      v-if="dataItem.currentData.yearPerson1 == yearItem.year"
                      :class="`subtitle-2 font-weight-light primary--text`"
                      v-text="dataItem.currentData.descriptionEnhancedPerson1"
                    ></h2>
                    <h2
                      v-else-if="
                        dataItem.currentData.yearPerson2 == yearItem.year
                      "
                      :class="`subtitle-2 font-weight-light primary--text`"
                      v-text="dataItem.currentData.descriptionEnhancedPerson2"
                    ></h2>
                  </div>
                  <div v-else-if="dataItem.type == 'Debt Management'">
                    <h2
                      :class="`subtitle-2 font-weight-light primary--text`"
                      v-text="dataItem.currentData.descriptionEnhanced"
                    ></h2>
                  </div>
                  <div v-else>
                    <h2
                      :class="`subtitle-2 font-weight-light primary--text`"
                      v-text="dataItem.currentData.description"
                    ></h2>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card>

    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="ml-3">
            <span class="headline mt-4">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row v-if="goal.type == 'Emergency-Fund'">
                <v-row class="px-3">
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="goal.type"
                      v-if="formTitle == 'Add Goal'"
                      :items="goalTypes"
                      label="Goal Type"
                      class="primary--text"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6"> </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n8">
                    <v-text-field
                      v-model.number="goal.currentData.year"
                      :rules="yearReqRules"
                      label="Goal target year"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n8">
                    <v-text-field
                      v-model.number="goal.currentData.value"
                      :rules="numberReqRules"
                      label="Emergency fund amount"
                      :suffix="currencyApp"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-row>

              <v-row v-else-if="goal.type == 'Retirement'">
                <v-row class="px-3">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model.number="goal.currentData.retirementAgePerson1"
                      :rules="ageReqRules"
                      :label="'Retirement age ' + clientPerson1.firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-if="clientPerson2 != ''"
                      v-model.number="goal.currentData.retirementAgePerson2"
                      :rules="ageReqRules"
                      :label="'Retirement age ' + clientPerson2.firstName"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="6" class="mt-n8">
                    <v-text-field
                      v-model.number="goal.currentData.lifeExpectancyPerson1"
                      :rules="ageReqRules"
                      :label="'Life expectancy ' + clientPerson1.firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n8">
                    <v-text-field
                      v-if="clientPerson2 != ''"
                      v-model.number="goal.currentData.lifeExpectancyPerson2"
                      :rules="ageReqRules"
                      :label="'Life expectancy ' + clientPerson2.firstName"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" sm="6" md="6" class="mt-n8">
                    <v-select
                      v-model="goal.currentData.retirementExpenseType"
                      :items="retirementExpenseTypes"
                      label="Retirement Expense Type"
                      class="primary--text"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="goal.currentData.retirementExpenseType == 'fixed'"
                    cols="12"
                    sm="6"
                    md="6"
                    class="mt-n8"
                    ><v-text-field
                      v-model.number="goal.currentData.retirementExpenseValue"
                      :rules="numberReqRules"
                      label="Retirement expense p.a."
                      :suffix="currencyApp"
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" sm="6" md="6" class="mt-n8"
                    ><v-select
                      v-model="goal.currentData.retirementExpenseRate"
                      :rules="numberReqRules"
                      :items="retirementExpenseRate"
                      label="Rate from current expenses p.a."
                      class="primary--text"
                    ></v-select>
                  </v-col>
                  <!-- <v-col cols="12" sm="7" md="7" class="mt-n8">
                    <v-select
                      v-model="goal.currentData.postRetirementRiskType"
                      :items="postRetirementRiskTypeList"
                      label="Post retirement risk-type"
                      class="primary--text"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="5" md="5" class="mt-n8">
                    <v-text-field
                      v-if="
                        goal.currentData.postRetirementRiskType != 'current'
                      "
                      v-model.number="
                        goal.currentData.postRetirementRiskTypeFromYear
                      "
                      :rules="yearReqRules"
                      label="year, changes apply"
                    ></v-text-field>
                  </v-col> -->
                </v-row>
              </v-row>

              <v-row v-else>
                <v-row class="px-3">
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="goal.type"
                      :readonly="
                        formTitle ==
                        'Edit ' +
                          (this.goal.currentData.recurring == true
                            ? 'recurring '
                            : '') +
                          this.goal.type +
                          ' Goal'
                          ? true
                          : false
                      "
                      :items="goalTypes"
                      label="Goal Type"
                      class="primary--text"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="
                      goal.type == 'Upsize' ||
                        goal.type == 'Downsize' ||
                        goal.type == 'Sale'
                    "
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-select
                      v-model="goal.currentData.idRelated"
                      :items="assetNames"
                      :rules="numberReqRules"
                      label="Asset Name"
                      class="primary--text"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-else-if="goal.type == 'Debt Management'"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-select
                      v-model="goal.currentData.idRelated"
                      :items="liabilityNames"
                      :rules="numberReqRules"
                      label="Liability Name"
                      class="primary--text"
                    ></v-select>
                  </v-col>
                  <v-col v-else cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="goal.name"
                      :rules="nameReqRules"
                      label="Goal Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n8">
                    <v-text-field
                      v-model="goal.targetYear"
                      :rules="yearReqRules"
                      label="Target Year"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="goal.type != 'Custom'"
                    cols="12"
                    sm="6"
                    md="6"
                    class="mt-n8"
                  >
                    <v-text-field
                      v-model.number="goal.currentData.value"
                      :rules="numberReqRules"
                      :label="
                        goal.type == 'Debt Management'
                          ? 'Extra principal'
                          : goal.type == 'Sale' || goal.type == 'Downsize'
                          ? 'Price'
                          : 'Cost'
                      "
                      :suffix="currencyApp"
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" sm="6" md="6" class="mt-n8"> </v-col>
                  <v-col
                    v-if="goal.type == 'Custom'"
                    cols="12"
                    sm="12"
                    md="12"
                    class="mt-n8"
                  >
                    <v-textarea
                      v-model="goal.currentData.description"
                      label="type your goal description here ..."
                      counter
                      maxlength="100"
                      outlined
                      full-width
                      class="mt-6"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    v-if="
                      (goal.type == 'Purchase' ||
                        goal.type == 'Education' ||
                        goal.type == 'Debt Management') &&
                        formTitle == 'Add Goal'
                    "
                    cols="12"
                    sm="12"
                    md="12"
                    class="mt-n12"
                  >
                    <v-checkbox
                      v-model="goal.currentData.recurring"
                      class="subtitle-1 primary--text"
                      hide-details
                      label="recurring goal"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-row v-if="goal.currentData.recurring == true" class="px-3">
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="goal.currentData.interval"
                        :rules="numberReqRules"
                        label="Time interval"
                        prefix="Every"
                        suffix="year(s)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="goal.currentData.period"
                        :rules="numberReqRules"
                        label="Time period (total)"
                        prefix="for"
                        suffix="years"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>
            <div v-if="formTitle == 'Add Goal'">
              <v-btn
                color="success"
                :disabled="!valid"
                @click="createItem"
                class="mr-4 mb-4"
                text
                >Create</v-btn
              >
            </div>
            <div v-else>
              <v-btn
                color="success"
                :disabled="!valid"
                @click="updateItem"
                class="mr-4 mb-4"
                text
                >Update</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent v-model="dialogConfirm" max-width="300px">
      <v-card class="subtitle-1">
        <v-card-text
          class="title font-weight-medium primary--text text-center pt-4"
        >
          <span
            >Are you sure you want to delete this {{ goal.type }} goal ?</span
          >
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            class="primary--text ml-2 mb-4"
            text
            small
            @click="dialogConfirm = !dialogConfirm"
            >No</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="success text--white"
            :disabled="!valid"
            class="mr-2 mb-4"
            small
            @click="deleteItem"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import SystemService from '@/services/SystemService.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientLedgerGoals',

  data() {
    return {
      valid: true,
      dialog: false,
      dialogConfirm: false,
      hover: false,
      editedId: -1,
      newItemId: 1,
      goal: StaticDataService.getGoal(),
      retirementExpenseTypes: [
        { text: 'Expense-Rate', value: 'rate' },
        { text: 'Fixed Expense', value: 'fixed' }
      ],
      retirementExpenseRate: [
        { text: '50%', value: 50 },
        { text: '60%', value: 60 },
        { text: '70%', value: 70 },
        { text: '80%', value: 80 },
        { text: '90%', value: 90 },
        { text: '100%', value: 100 },
        { text: '110%', value: 110 },
        { text: '120%', value: 120 },
        { text: '130%', value: 130 },
        { text: '140%', value: 140 },
        { text: '150%', value: 150 }
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v => !v || (v && v.length <= 20) || 'must be less than 20 characters'
      ],
      yearReqRules: [
        v => !!v || 'Year is required',
        v =>
          !v ||
          /^(202[1-9]|20[3-9]\d|210\d|211[0-9])$/.test(v) || // Range from 2021 to 2119
          'current/future year (4-digits)'
      ],
      ageReqRules: [
        v => !!v || 'Age is required',
        v =>
          !v ||
          /^[0-9]{1,2}$/.test(v) || // Range from 1 to 99
          'age (1 - 99)'
      ],
      numberReqRules: [
        v => !!v || 'Field is required',
        v => !v || /^\d{0,10}(\.\d{1,2})?$/.test(v) || 'Requires numbers only'
      ],
      dateReqRules: [
        v => !!v || 'Date is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ]
    }
  },

  methods: {
    createItem() {
      if (this.$refs.form.validate()) {
        // create goal (current and proposed goal data will be identical - see create goal action) in DDB and state
        this.$store.dispatch('createGoal', this.goal).then(() => {
          this.close()
        })
      }
    },

    editItem(item) {
      this.goal = Object.assign({}, item)
      this.editedId = item.id
      this.dialog = true
    },

    updateItem() {
      if (this.$refs.form.validate()) {
        // update goal (current data only) in DDB and state
        this.$store.dispatch('updateGoal', this.goal).then(() => {
          this.close()
        })
      }
    },

    deleteConfirm(item) {
      this.goal = Object.assign({}, item)
      this.editedId = item.id
      this.dialogConfirm = true
    },

    deleteItem() {
      this.$store.dispatch('deleteGoal', this.goal).then(() => {
        this.dialogConfirm = false
        this.goal = StaticDataService.getGoal()
        this.editedId = -1
      })
    },

    close() {
      this.dialog = false
      this.goal = StaticDataService.getGoal()
      this.$refs.form.resetValidation()
      this.editedId = -1
    },

    openPlan(item) {
      // map chosen item exactly to financialPlan item index (data basis is same computed property than in ClientProjectionFinancialPlan)
      this.newItemId = this.goalItems.findIndex(obj => obj.id == item.id)
      // preset selectedItemId in store in preparation of navigating/opening the chosen goal in the financialPlan
      this.$store.commit('SET_FINANCIAL_PLAN_NEW_ITEM_ID', this.newItemId)
      // route to financialPlan page
      this.$router.push({
        name: 'clientProjection',
        params: { tabs: 'financialplan' }
      })
    }
  },

  computed: {
    formTitle() {
      return this.editedId === -1
        ? 'Add Goal'
        : 'Edit ' +
            (this.goal.currentData.recurring == true ? 'recurring ' : '') +
            this.goal.type +
            ' Goal'
    },

    goalTypes() {
      // offer Emergency Fund option in Drop only when it's missing ...
      let emFund = false
      Object.entries(this.clientGoals).forEach(([, goal]) => {
        if (goal.type == 'Emergency-Fund') {
          emFund = true
        }
      })
      return emFund == true
        ? [
            'Purchase',
            'Sale',
            'Debt Management',
            'Education',
            'Upsize',
            'Downsize',
            'Custom'
          ]
        : [
            'Purchase',
            'Sale',
            'Debt Management',
            'Emergency-Fund',
            'Education',
            'Upsize',
            'Downsize',
            'Custom'
          ]
    },

    assetNames() {
      let list = []
      Object.entries(this.clientAssetsName).forEach(([, asset]) => {
        if (
          asset.class == 'Property' ||
          asset.class == 'Non Working Asset' ||
          asset.class == 'Other'
        ) {
          list.push(asset)
        }
      })
      return list
    },

    liabilityNames() {
      let list = []
      Object.entries(this.clientLiabilities).forEach(([, val]) => {
        list.push({
          text: val.name,
          value: val.id
        })
      })
      return list
    },

    postRetirementRiskTypeList() {
      let list = []
      Object.entries(this.adminDefinitionsRiskTypeNames).forEach(
        ([, riskTypeName]) => {
          if (this.clientLedger.ledger.conditions.riskType == riskTypeName) {
            list.push({
              text: this.clientLedger.ledger.conditions.riskType + ' (current)',
              value: 'current'
            })
          } else {
            list.push({ text: riskTypeName, value: riskTypeName })
          }
        }
      )

      return list
    },

    clientGoalsSorted() {
      let goalsSorted = [
        {
          id: 0,
          name: 'today',
          targetYear: SystemService.yearToday(),
          currentData: { year: SystemService.yearToday() }
        }
      ]
      let goalYears = [parseInt(SystemService.yearToday())]
      let goalsSortedTimeline = []
      Object.entries(this.clientGoals).forEach(([, goal]) => {
        // Process Emergency Fund Goal
        if (goal.type == 'Emergency-Fund') {
          goalsSorted.push(goal)
          goalYears.push(parseInt(goal.currentData.year))
        }

        // Process Retirement Goal Individual Account
        else if (goal.id == 2 && this.clientProfileType == 'Individual') {
          goalsSorted.push(goal)
          goalYears.push(parseInt(goal.currentData.yearPerson1))
        }

        // Process Retirement Goal Joint Account
        else if (goal.id == 2 && this.clientProfileType == 'Joint') {
          goalsSorted.push(goal)
          goalYears.push(parseInt(goal.currentData.yearPerson1))
          goalYears.push(parseInt(goal.currentData.yearPerson2))
        }

        // Process individual Purchase Goal
        else if (goal.type == 'Purchase' || goal.type == 'Education') {
          if (goal.currentData.recurring == true) {
            let min = parseInt(goal.targetYear)
            let max =
              parseInt(goal.targetYear) + parseInt(goal.currentData.period) - 1
            let step = parseInt(goal.currentData.interval)
            for (let year = min; year <= max; year += step) {
              let recurringGoal = JSON.parse(JSON.stringify(goal))
              recurringGoal.currentData.year = year
              goalsSorted.push(recurringGoal)
              goalYears.push(parseInt(year))
            }
          } else {
            goalsSorted.push(goal)
            goalYears.push(parseInt(goal.targetYear))
          }
        }

        // Process individual Upsize Goal
        else if (goal.type == 'Upsize') {
          goalsSorted.push(goal)
          goalYears.push(parseInt(goal.targetYear))
        }

        // Process individual Debt Management Goal
        else if (goal.type == 'Debt Management') {
          if (goal.currentData.recurring == true) {
            let min = parseInt(goal.targetYear)
            let max =
              parseInt(goal.targetYear) + parseInt(goal.currentData.period) - 1
            let step = parseInt(goal.currentData.interval)
            for (let year = min; year <= max; year += step) {
              let recurringGoal = JSON.parse(JSON.stringify(goal))
              recurringGoal.currentData.year = year
              goalsSorted.push(recurringGoal)
              goalYears.push(parseInt(year))
            }
          } else {
            goalsSorted.push(goal)
            goalYears.push(parseInt(goal.targetYear))
          }
        } else {
          // no modification needed (e.g. custom-goal)
          goalsSorted.push(goal)
          goalYears.push(parseInt(goal.targetYear))
        }
      })

      // get a SET of sorted years in an array to be used to loop through (combine identical years)
      let years = Array.from(new Set(goalYears)).sort()
      console.log('years set sorted = ', years)
      console.log('goals sorted = ', goalsSorted)

      // loop through and take first year
      Object.entries(years).forEach(([, year]) => {
        let goalItem = { year: year, data: [] }
        // loop through and look for year
        Object.entries(goalsSorted).forEach(([, goal]) => {
          if (
            goal.type == 'Retirement' &&
            goal.currentData.yearPerson1 == year
          ) {
            goalItem.data.push(goal)
          } else if (
            goal.type == 'Retirement' &&
            goal.currentData.yearPerson2 == year
          ) {
            goalItem.data.push(goal)
          } else {
            if (goal.currentData.year == year) {
              // if "today" match is found (id = 0) copy individual today item into new data structure
              if (goal.id == 0) {
                goalsSortedTimeline.push({ year: 'Today', data: [goal] })
              } else {
                // if match is found copy goalItem into data array in new data structure
                goalItem.data.push(goal)
              }
            }
          }
        })
        // Check if at least one data item is present, then push goalItem to be displayed in timeline
        if (goalItem.data[0]) {
          goalsSortedTimeline.push(goalItem)
        }
      })
      console.log('goals sorted timeline = ', goalsSortedTimeline)
      return goalsSortedTimeline
    },

    goalItems() {
      let predefinedItems = []
      let individualItems = []
      Object.entries(this.clientGoals).forEach(([, goal]) => {
        // add retirement goal (if any) on first position
        if (goal.id == 2) {
          predefinedItems.push(goal)
        }
        // remove predefined emergency-fund item if not in use (value = 0)
        // or if in use, add on second position
        else if (goal.type == 'Emergency-Fund') {
          if (goal.currentData.value != 0) {
            predefinedItems.push(goal)
          }
        }
        // add individual Goals (if any)
        else {
          individualItems.push(goal)
        }
      })
      // sort individual items acending according to targetYear
      individualItems.sort(function(a, b) {
        return a.targetYear - b.targetYear
      })
      // combine arrays
      let items = predefinedItems.concat(individualItems)
      return items
    },

    ...mapState(['clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
