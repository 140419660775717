<template>
  <div>
    <v-card class="mt-7" flat>
      <v-card flat color="white" class="text-center">
        <v-card-title class="headline font-weight-normal primary--text">
          <v-row>
            <v-col cols="12" sm="3" md="3" class="text-left"> </v-col>

            <v-col cols="12" sm="4" md="4"> </v-col>
            <v-col cols="12" sm="5" md="5"> </v-col>
          </v-row>
        </v-card-title>
      </v-card>

      <template>
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(obj, i) of tableData"
            :key="i"
            class="elevation-0 info"
          >
            <v-expansion-panel-header class="py-0"
              ><v-card
                flat
                class="transparent subtitle-1 font-weight-medium primary--text text-left"
              >
                {{ obj.class }}
              </v-card>
              <v-spacer />
              <v-card
                flat
                class="transparent subtitle-1 font-weight-medium primary--text text-right mr-8"
              >
                {{
                  obj.sum.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }}
              </v-card>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="obj.assets"
                item-key="i"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                hide-default-footer
              >
                <template v-slot:top> </template>

                <template v-slot:item.name="{ item }">
                  <v-btn
                    text
                    retain-focus-on-click
                    color="success"
                    class="text-capitalize ml-n4 my-n4"
                    @click="editItem(item)"
                    ><div v-if="item.class == 'Precious Metal'">
                      {{ item.name }}
                      {{
                        item.catText
                          ? ' / ' +
                            item.catText +
                            ' / ' +
                            (item.amount * item.unit).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                              }
                            ) +
                            ' oz '
                          : ''
                      }}
                    </div>
                    <div v-else-if="item.class == 'Crypto Currency'">
                      {{ item.name }}
                      {{
                        item.catText
                          ? ' / ' + item.amount + ' ' + item.catText
                          : ''
                      }}
                    </div>
                    <div v-else>
                      {{ item.name }}
                      {{ item.catText ? ' / ' + item.catText : '' }}
                    </div>
                  </v-btn>
                </template>

                <template v-slot:item.extraContribution="{ item }">
                  <div v-if="item.class == 'Pension Scheme'">
                    <v-btn
                      text
                      retain-focus-on-click
                      color="success"
                      class="text-capitalize ml-n4 my-n4"
                      @click="editItemExtraContribution(item)"
                      >Extra Contribution</v-btn
                    >
                  </div>
                </template>

                <template v-slot:item.priceTodayLocal="{ item }">
                  <div v-if="item.currencyLocal != currencyApp">
                    <v-card-text class="my-n4"
                      >{{
                        item.priceTodayLocal.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      }}
                      {{ item.currencyLocal }}</v-card-text
                    >
                  </div>
                </template>

                <template v-slot:item.priceTodayApp="{ item }">
                  <v-card-text class="my-n4"
                    >{{
                      item.priceTodayApp.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                    {{ currencyApp }}</v-card-text
                  >
                </template>

                <template v-slot:no-data>
                  <v-card flat>
                    <v-card-text class="title font-weight-regular">
                      There are no Assets defined yet ...
                    </v-card-text>
                  </v-card>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <v-card flat color="white" class="text-center">
        <v-card-title class="headline font-weight-normal primary--text">
          <v-row v-if="clientAssetsSum != 0">
            <v-col cols="12" sm="3" md="3" class="text-left"
              ><v-tooltip right>
                <template v-slot:activator="{ on, attrs }"
                  ><v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                    fab
                    dark
                    x-small
                    top
                    left
                    absolut
                    @click="dialog = !dialog"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn></template
                >
                <span>Add Asset</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" sm="4" md="4"
              ><div class="text-right">
                total:
              </div>
            </v-col>
            <v-col cols="12" sm="5" md="5"
              ><div class="text-right mr-12">
                {{
                  clientAssetsSum.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }}
              </div>
            </v-col>
          </v-row>
          <v-row v-else align="center" justify="space-around">
            <v-card flat>
              <v-card-text class="title font-weight-regular">
                There are no Assets defined yet, let's get started
                <v-btn
                  color="success"
                  fab
                  dark
                  x-small
                  class="mb-2 ml-2"
                  @click="dialog = !dialog"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-title>
      </v-card>
    </v-card>

    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="ml-3">
            <span class="headline mt-4">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-select
                    v-if="itemsOwner.length > 1"
                    v-model="asset.owner"
                    :items="itemsOwner"
                    label="Asset owner"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>
                <v-col cols="12" sm="8" md="8" class="mt-n8">
                  <v-select
                    v-model="asset.class"
                    :items="assetClassItems"
                    label="Asset class"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>

                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-select
                    v-model="asset.catText"
                    :items="itemsCategory"
                    item-text="text"
                    item-value="text"
                    label="Asset sub-category"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-text-field
                    v-model.number="growthValue"
                    :rules="numberRules"
                    label="Est. growth"
                    suffix="% p.a."
                    class="primary--text"
                    min="0"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-text-field
                    v-model="asset.name"
                    :rules="nameReqRules"
                    label="Asset name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-select
                    v-model="asset.currencyLocal"
                    :items="itemsFiat"
                    label="Currency"
                    class="primary--text"
                  ></v-select>
                </v-col>

                <v-row
                  v-if="
                    asset.class == 'Cash' ||
                      asset.catText == 'Cash' ||
                      asset.class == 'Insurance'
                  "
                  class="mx-0"
                ></v-row>

                <v-row v-else-if="asset.class == 'Pension Scheme'" class="mx-0">
                  <v-col cols="12" sm="6" md="6" class="mt-n5">
                    <template>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        top
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="asset.pensionOpeningDate"
                            label="Date of inception"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="asset.pensionOpeningDate"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1999-01-01"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </template>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n5">
                    <v-text-field
                      v-model.number="asset.pensionOpeningValueLocal"
                      :rules="numberRules"
                      label="Opening amount"
                      :suffix="asset.currencyLocal"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="mt-n8">
                    <v-text-field
                      v-model.number="asset.pensionRegularContributionLocal"
                      label="Regular contribution"
                      :suffix="asset.currencyLocal"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n8">
                    <v-select
                      v-model.number="asset.pensionRegularPeriod"
                      :items="itemsPeriod"
                      label="Contribution period"
                      class="primary--text"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row v-else class="mx-0">
                  <v-col cols="12" sm="6" md="6" class="mt-n5">
                    <template>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        top
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="asset.costDate"
                            label="Date of purchase"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="asset.costDate"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1999-01-01"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </template>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n5">
                    <v-text-field
                      v-model.number="asset.costValueLocal"
                      :rules="numberRules"
                      label="Cost"
                      :suffix="asset.currencyLocal"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="asset.class == 'Precious Metal'" class="mx-0">
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <v-text-field
                      v-model.number="asset.amount"
                      :rules="numberReqRules"
                      label="Amount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <v-select
                      v-model="asset.unit"
                      :items="itemsUnitPM"
                      label="Unit"
                      class="primary--text"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row v-if="asset.class == 'Crypto Currency'" class="mx-0">
                  <v-col cols="12" sm="6" md="6" class="mt-n2">
                    <v-text-field
                      v-model.number="asset.amount"
                      :rules="numberReqRules"
                      label="Amount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n2">
                    <v-text-field
                      v-model="asset.catText"
                      label="Unit"
                      class="primary--text"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    asset.class == 'Precious Metal' ||
                      asset.class == 'Crypto Currency'
                  "
                  class="mx-0"
                >
                  <v-col cols="12" sm="12" md="12" class="mt-n8"> </v-col>
                </v-row>
                <v-row v-else class="mx-0">
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <template>
                      <v-menu
                        ref="menuSec"
                        v-model="menuSec"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        top
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="asset.priceDate"
                            label="Date of valuation"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="asset.priceDate"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1999-01-01"
                          @change="saveSec"
                        ></v-date-picker>
                      </v-menu>
                    </template>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <v-text-field
                      v-model.number="asset.priceValueLocal"
                      :rules="
                        asset.class == 'Pension Scheme'
                          ? numberRules
                          : numberReqRules
                      "
                      :label="labelPriceValueLocal"
                      :suffix="asset.currencyLocal"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    asset.class == 'Pension Scheme' &&
                      asset.catText != 'KiwiSaver'
                  "
                  class="mx-0"
                >
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <v-select
                      v-model="asset.maturityOption"
                      :items="maturityItems"
                      label="Maturity option"
                      class="primary--text"
                    ></v-select>
                  </v-col>

                  <v-row v-if="asset.maturityOption == 'lumpsum'" class="mx-0">
                    <v-col cols="12" sm="6" md="6" class="mt-n6">
                      <template>
                        <v-menu
                          ref="menuTrd"
                          v-model="menuTrd"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          top
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="maturityValue"
                              label="Date of lumpsum payment"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="maturityValue"
                            max="2070-12-31"
                            :min="new Date().toISOString().substr(0, 10)"
                            @change="saveTrd"
                          ></v-date-picker>
                        </v-menu>
                      </template>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n8"> </v-col>
                  </v-row>

                  <v-row v-else class="mx-0">
                    <v-col cols="12" sm="6" md="6" class="mt-n6">
                      <v-text-field
                        v-model.number="asset.pensionPayment"
                        label="Payment Amount"
                        :suffix="asset.currencyLocal"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n6">
                      <v-select
                        v-model.number="asset.pensionPaymentPeriod"
                        :items="itemsPeriod"
                        label="Payment period"
                        class="primary--text"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn class="error--text mb-4 ml-6" @click="deleteItem" text
              >Delete</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>
            <div v-if="formTitle == 'Edit Asset'">
              <v-btn
                color="success"
                :disabled="!valid"
                @click="updateItem"
                class="mr-4 mb-4"
                text
                >Update</v-btn
              >
            </div>
            <div v-else>
              <v-btn
                color="success"
                :disabled="!valid"
                @click="createItem"
                class="mr-4 mb-4"
                text
                >Create</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent v-model="dialogExtraContribution" max-width="450px">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title class="ml-3">
            <span class="headline primary--text mt-4">Extra Contribution</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row v-if="asset.pensionExtraContribution != ''" class="mt-n4">
                <v-col cols="12" sm="12" md="12">
                  <template>
                    <v-data-table
                      :headers="headersExtraContribution"
                      :items="asset.pensionExtraContribution"
                      item-key="i"
                      :sort-by.sync="sortByExtra"
                      :sort-desc.sync="sortDescExtra"
                      hide-default-footer
                      dense
                    >
                      <template v-slot:item.date="{ item }">
                        <v-card-text class="my-n4">
                          {{ item.date }}
                        </v-card-text>
                      </template>

                      <template v-slot:item.amount="{ item }">
                        <v-card-text class="my-n4">
                          {{
                            item.amount.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0
                            })
                          }}
                          {{ asset.currencyLocal }}
                        </v-card-text>
                      </template>

                      <template v-slot:item.actions="{ item }">
                        <v-dialog
                          persistent
                          v-model="dialogConfirm"
                          max-width="270px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="success"
                              dark
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small class="my-n4">mdi-delete</v-icon>
                            </v-btn>
                          </template>

                          <v-card class="subtitle-1">
                            <v-card-text
                              class="subtitle-1 font-weight-medium primary--text pt-4"
                            >
                              <span
                                >Are you sure you want to delete this
                                contribution?</span
                              >
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                class="primary--text ml-2 mb-4"
                                text
                                @click="close"
                                >Cancel</v-btn
                              >
                              <v-spacer></v-spacer>
                              <v-btn
                                color="success"
                                class="mb-4"
                                text
                                @click="deleteExtraContributionItem(item)"
                                >OK</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>

                      <template v-slot:no-data>
                        <v-card flat>
                          <v-card-text class="subtitle-1 font-weight-regular">
                            There are no extra contributions yet ...
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-data-table>
                  </template>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mt-n6">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="mt-n8"> </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-card-text
                    class="subtitle-2 font-weight-medium primary--text ml-3"
                    >Total:
                    {{
                      extraContributionSum.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                    {{ asset.currencyLocal }}
                  </v-card-text>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="mt-n8"> </v-col>

                <v-col cols="12" sm="12" md="12" class="mt-n6">
                  <v-divider class="mt-n3"></v-divider>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mt-n6">
                  <v-card-title class="ml-n4">
                    <span
                      class="subtitle-1 primary--text font-weight-medium mt-4"
                      >Add new contribution</span
                    >
                  </v-card-title>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6" class="mt-n10 mb-n12">
                  <template>
                    <v-menu
                      ref="menuFth"
                      v-model="menuFth"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      top
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="extraContribution.date"
                          :rules="dateReqRules"
                          label="Contribution date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="extraContribution.date"
                        :max="new Date().toISOString().substr(0, 10)"
                        :min="asset.pensionOpeningDate"
                        @change="saveFth"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n10 mb-n12">
                  <v-text-field
                    v-model.number="extraContribution.amount"
                    :rules="numberReqRules"
                    :suffix="asset.currencyLocal"
                    label="Amount"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>
            <v-btn
              color="success"
              :disabled="!valid"
              @click="createExtraContributionItem"
              class="mr-4 mb-4"
              text
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import SystemService from '@/services/SystemService.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientLedgerAssets',

  props: { assetClassItems: { type: Array } },

  data() {
    return {
      valid: true,
      dialog: false,
      dialogExtraContribution: false,
      dialogConfirm: false,
      menu: false,
      menuSec: false,
      menuTrd: false,
      menuFth: false,
      editedId: -1,
      sortByExtra: 'date',
      sortDescExtra: true,
      sortBy: ['name', 'id'],
      sortDesc: [false, false],
      extraContribution: { date: SystemService.dateToday(), amount: '' },
      maturityItems: [
        { text: 'Lumpsum', value: 'lumpsum' },
        { text: 'Pension', value: 'pension' }
      ],
      asset: StaticDataService.getAsset(),
      itemsFiat: StaticDataService.getUnitFiat(),
      itemsPeriod: [
        { text: 'per year', value: 1 },
        { text: 'per month', value: 12 },
        { text: 'per fortnight', value: 26 },
        { text: 'per week', value: 52 }
      ],
      itemsUnitPM: StaticDataService.getUnitPreciousMetal(),
      itemsOwner: this.$store.getters.clientAccHolderItems,
      headers: [
        {
          text: 'Asset Name / Category',
          align: 'left',
          value: 'name',
          class: 'subtitle-2 font-weight-medium primary--text'
        },
        {
          text: '',
          align: 'left',
          value: 'extraContribution',
          class: 'subtitle-2 font-weight-medium primary--text',
          sortable: false
        },
        {
          text: 'Value (local)',
          align: 'right',
          value: 'priceTodayLocal',
          width: 180,
          class: 'subtitle-2 font-weight-medium primary--text pr-8',
          sortable: false
        },
        {
          text: 'Value',
          align: 'right',
          value: 'priceTodayApp',
          width: 180,
          class: 'subtitle-2 font-weight-medium primary--text pr-8'
        }
      ],
      headersExtraContribution: [
        {
          text: 'Date',
          align: 'center',
          value: 'date',
          class: 'subtitle-2 font-weight-medium primary--text'
        },
        {
          text: 'Amount',
          align: 'right',
          value: 'amount',
          class: 'subtitle-2 font-weight-medium primary--text'
        },
        {
          text: 'Action',
          align: 'center',
          value: 'actions',
          class: 'subtitle-2 font-weight-medium primary--text',
          sortable: false
        }
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      numberRules: [
        v => !v || /^\d{0,10}(\.\d{1,2})?$/.test(v) || 'Requires numbers only'
      ],
      numberReqRules: [
        v => !!v || 'Number is required',
        v => !v || /^\d{0,10}(\.\d{1,2})?$/.test(v) || 'Requires numbers only'
      ],
      dateReqRules: [
        v => !!v || 'Date is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ]
    }
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },

    menuSec(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },

    menuTrd(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },

    menuFth(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },

  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },

    saveSec(date) {
      this.$refs.menuSec.save(date)
    },

    saveTrd(date) {
      this.$refs.menuTrd.save(date)
    },

    saveFth(date) {
      this.$refs.menuFth.save(date)
    },

    createItem() {
      if (this.$refs.form.validate()) {
        this.setGrowthRate(this.growthValue)
        this.setMaturityDate(this.maturityValue)
        this.$store.dispatch('createAsset', this.asset).then(() => {
          this.close()
        })
      }
    },

    setGrowthRate(value) {
      this.asset.growthRate = value
    },

    setMaturityDate(date) {
      this.asset.maturityDate = date
    },

    editItem(item) {
      this.asset = Object.assign({}, item)
      this.editedId = item.id
      this.dialog = true
    },

    updateItem() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('updateAsset', this.asset).then(() => {
          this.close()
        })
      }
    },

    deleteItem() {
      this.$store.dispatch('deleteAsset', this.asset).then(() => {
        this.close()
      })
    },

    createExtraContributionItem() {
      if (this.$refs.form.validate()) {
        this.asset.pensionExtraContribution.push(this.extraContribution)
        this.$store.dispatch('updateAsset', this.asset).then(() => {
          ;(this.extraContribution = {
            date: SystemService.dateToday(),
            amount: ''
          }),
            this.close()
        })
      }
    },

    editItemExtraContribution(item) {
      this.asset = Object.assign({}, item)
      this.editedId = item.id
      this.dialogExtraContribution = true
    },

    deleteExtraContributionItem(item) {
      let index1 = this.asset.pensionExtraContribution.findIndex(
        obj => obj == item
      )
      this.asset.pensionExtraContribution.splice(index1, 1)
      this.$store.dispatch('updateAsset', this.asset)
      this.close()
    },

    close() {
      this.dialog = false
      this.dialogExtraContribution = false
      this.dialogConfirm = false
      this.asset = StaticDataService.getAsset()
      this.$refs.form.resetValidation()
      this.editedId = -1
    }
  },

  computed: {
    formTitle() {
      return this.editedId === -1 ? 'New Asset' : 'Edit Asset'
    },

    labelPriceValueLocal() {
      return this.asset.class == 'Insurance' ||
        this.asset.class == 'Pension Scheme' ||
        this.asset.class == 'Business'
        ? 'Valuation amount'
        : 'Value'
    },

    itemsCategory() {
      let items = []
      Object.entries(this.assetCategoryItems).forEach(([, val]) => {
        if (this.asset.class == val.class) {
          items = val.category
        }
      })
      return items
    },

    assetCategoryItems() {
      let items = []
      this.assetClassItems.forEach(item => {
        let obj = { class: item, category: [] }
        let cat = []
        Object.entries(this.clientAssets).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.catText)
          }
        })
        Object.entries(this.adminDefinitionsAssets).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.category)
          }
        })
        Object.entries(this.advisorDefinitionsAssets).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.category)
          }
        })
        obj.category = Array.from(new Set(cat))
        items.push(obj)
      })
      return items
    },

    extraContributionSum() {
      let sum = 0
      Object.entries(this.asset.pensionExtraContribution).forEach(([, obj]) => {
        sum += obj.amount
      })
      return sum
    },

    growthValue: {
      // This getter only updates the estimated growth parameter in the dialog-box. To actually update the
      // asset.growthRate variable, the method setGrowthRate() has to be utilized before creating a new asset!
      get: function() {
        let value = 0
        Object.entries(this.advisorDefinitionsAssets).forEach(([, val]) => {
          if (
            this.asset.class == val.class &&
            this.asset.catText == val.category
          ) {
            value = val.growth
          }
        })
        return this.asset.growthRate != null ? this.asset.growthRate : value
      },
      // This setter only updates the asset.growthRate variable, when the input value actually changes!! E.g. if
      // the suggested assumption is chosen, no update of the variable asset.growthRate takes place. That's why
      // in addition the method setGrowthRate() is needed, as mentioned before under getter description.
      set: function(newValue) {
        this.asset.growthRate = newValue
      }
    },

    maturityValue: {
      get: function() {
        let date = ''
        if (this.asset.class == 'Pension Scheme') {
          date =
            (
              Number(this.clientProfile.birthDate.substring(0, 4)) + 65
            ).toString() + this.clientProfile.birthDate.slice(4)
          return this.asset.maturityDate != null
            ? this.asset.maturityDate
            : date
        } else {
          return this.asset.maturityDate != null
            ? this.asset.maturityDate
            : date
        }
      },
      set: function(newValue) {
        this.asset.maturityDate = newValue
      }
    },

    // Asset Expansion Panel Computed Property
    // Return 1. Array of active classes (for Tabs)
    //        2. Array of Asset-Objects per class
    //        3. Sum of Assets per class
    // this.clientAssets is complete List of al client assets
    tableData() {
      let data = []
      let classes = []
      // get all active classes
      Object.entries(this.clientAssets).forEach(([, obj]) => {
        classes.push(obj.class)
      })
      let activeClasses = Array.from(new Set(classes))
      // build asset object data for drawer
      Object.entries(activeClasses).forEach(([, activeClass]) => {
        let sum = 0
        let assets = []
        Object.entries(this.clientAssets).forEach(([, obj]) => {
          if (activeClass == obj.class) {
            sum += obj.priceTodayApp
            assets.push(obj)
          }
        })
        data.push({ class: activeClass, sum: sum, assets: assets })
      })
      data.sort(function(a, b) {
        return b.sum - a.sum
      })
      return data
    },

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
