<template>
  <div>
    <v-card class="mt-7" flat>
      <v-card flat color="white" class="text-center">
        <v-card-title class="headline font-weight-normal primary--text">
          <v-row>
            <v-col cols="12" sm="3" md="3" class="text-left"> </v-col>

            <v-col cols="12" sm="4" md="4"> </v-col>
            <v-col cols="12" sm="5" md="5"> </v-col>
          </v-row>
        </v-card-title>
      </v-card>

      <template>
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(obj, i) of tableData"
            :key="i"
            class="elevation-0 info"
          >
            <v-expansion-panel-header class="py-0"
              ><v-card
                flat
                class="transparent subtitle-1 font-weight-medium primary--text text-left"
              >
                {{ obj.class }}
              </v-card>
              <v-spacer />
              <v-card
                flat
                class="transparent subtitle-1 font-weight-medium primary--text text-right mr-8"
              >
                {{
                  obj.sum.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }} p.a.
              </v-card>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="obj.spending"
                item-key="i"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                hide-default-footer
              >
                <template v-slot:top> </template>

                <template v-slot:item.name="{ item }">
                  <v-btn
                    text
                    retain-focus-on-click
                    color="success"
                    class="text-capitalize ml-n4 my-n4"
                    @click="editItem(item)"
                    >{{ item.name
                    }}{{
                      item.idRelated == -1
                        ? ''
                        : ' / ' + relatedAsset(item.idRelated)
                    }}</v-btn
                  >
                </template>

                <template v-slot:item.spendingPaLocal="{ item }">
                  <div v-if="item.currencyLocal != currencyApp">
                    <v-card-text class="my-n4"
                      >{{
                        item.spendingPaLocal.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })
                      }}
                      {{ item.currencyLocal }}</v-card-text
                    >
                  </div>
                </template>

                <template v-slot:item.spendingPaTodayApp="{ item }">
                  <v-card-text class="my-n4"
                    >{{
                      item.spendingPaTodayApp.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    }}
                    {{ currencyApp }}</v-card-text
                  >
                </template>

                <template v-slot:no-data>
                  <v-card flat>
                    <v-card-text class="title font-weight-regular">
                      There are no Expenses defined yet ...
                    </v-card-text>
                  </v-card>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <v-card flat color="white" class="text-center">
        <v-card-title class="headline font-weight-normal primary--text">
          <v-row v-if="clientSpendingStreamsSum != 0">
            <v-col cols="12" sm="3" md="3" class="text-left"
              ><v-tooltip right>
                <template v-slot:activator="{ on, attrs }"
                  ><v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                    fab
                    dark
                    x-small
                    top
                    left
                    absolute
                    class="mt-8"
                    @click="dialog = !dialog"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn></template
                >
                <span>Add Expense</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" sm="4" md="4"
              ><div class="text-right">
                total:
              </div>
            </v-col>
            <v-col cols="12" sm="5" md="5"
              ><div class="text-right mr-12">
                {{
                  clientSpendingStreamsSum.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }} p.a.
              </div>
            </v-col>
          </v-row>
          <v-row v-else align="center" justify="space-around">
            <v-card flat>
              <v-card-text class="title font-weight-regular">
                There are no Expenses defined yet, let's get started
                <v-btn
                  color="success"
                  fab
                  dark
                  x-small
                  class="mb-2 ml-2"
                  @click="dialog = !dialog"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-title>
      </v-card>
    </v-card>

    <v-dialog persistent v-model="dialog" max-width="450px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="ml-3">
            <span class="headline mt-4">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-select
                    v-if="clientAccHolderItems.length > 1"
                    v-model="spendingStream.owner"
                    :items="clientAccHolderItems"
                    label="Expense owner"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>
                <v-col cols="12" sm="8" md="8" class="mt-n8">
                  <v-select
                    v-model="spendingStream.class"
                    :items="spendingStreamClassItems"
                    label="Expense class"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>

                <v-col cols="12" sm="8" md="8" class="mt-n8">
                  <v-select
                    v-model="spendingStream.catText"
                    :items="itemsCategory"
                    item-text="text"
                    item-value="text"
                    label="Expense sub-category"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>

                <v-col cols="12" sm="8" md="8" class="mt-n8">
                  <v-select
                    v-model="spendingStream.idRelated"
                    :items="clientAssetsName"
                    label="Related asset"
                    class="primary--text"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>

                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-text-field
                    v-model="spendingStream.name"
                    :rules="nameReqRules"
                    label="Expense name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-select
                    v-model="spendingStream.currencyLocal"
                    :items="itemsFiat"
                    label="Currency"
                    class="primary--text"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-text-field
                    v-model.number="spendingStream.spendingLocal"
                    :rules="numberReqRules"
                    label="Expense amount"
                    :suffix="spendingStream.currencyLocal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="mt-n8">
                  <v-select
                    v-model.number="spendingStream.spendingPeriod"
                    :items="itemsPeriod"
                    label="Period"
                    class="primary--text"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- <v-row v-if="spendingStream.idRelated == -1" class="mx-0">
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="spendingStream.projectionInclude"
                    :value="getRelatedAssetProjectionIncludeValue"
                    label="include this expense in projections"
                    color="success"
                    class="subtitle-1 primary--text mt-n3 mb-n4"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row v-else class="mx-0">
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="spendingStream.projectionInclude"
                    :value="getRelatedAssetProjectionIncludeValue"
                    label="include this related expense in projections"
                    color="success"
                    class="subtitle-1 primary--text mt-n3 mb-4"
                    hide-details
                    readonly
                  >
                  </v-checkbox>
                </v-col>
              </v-row> -->
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn class="error--text mb-4 ml-6" @click="deleteItem" text
              >Delete</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>
            <div v-if="formTitle == 'Edit Expense'">
              <v-btn
                color="success"
                :disabled="!valid"
                @click="updateItem"
                class="mr-4 mb-4"
                text
                >Update</v-btn
              >
            </div>
            <div v-else>
              <v-btn
                color="success"
                :disabled="!valid"
                @click="createItem"
                class="mr-4 mb-4"
                text
                >Create</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientLedgerSpending',

  props: { spendingStreamClassItems: { type: Array } },

  data() {
    return {
      valid: true,
      menu: false,
      dialog: false,
      editedId: -1,
      sortBy: ['name', 'id'],
      sortDesc: [false, false],
      spendingStream: StaticDataService.getSpendingStream(),
      itemsFiat: StaticDataService.getUnitFiat(),
      itemsPeriod: [
        { text: 'per year', value: 1 },
        { text: 'per month', value: 12 },
        { text: 'per fortnight', value: 26 },
        { text: 'per week', value: 52 }
      ],
      headers: [
        {
          text: 'Expense Name / Related Asset',
          align: 'left',
          value: 'name',
          class: 'subtitle-2 font-weight-medium primary--text'
        },
        {
          text: 'Amount p.a. (local)',
          align: 'right',
          value: 'spendingPaLocal',
          width: 180,
          class: 'subtitle-2 font-weight-medium primary--text pr-8',
          sortable: false
        },
        {
          text: 'Amount p.a.',
          align: 'right',
          value: 'spendingPaTodayApp',
          width: 160,
          class: 'subtitle-2 font-weight-medium primary--text'
        }
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberReqRules: [
        v => !!v || 'Number is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      dateReqRules: [
        v => !!v || 'Date is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ]
    }
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },

  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },

    createItem() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('createSpendingStream', this.spendingStream)
          .then(() => {
            this.close()
          })
      }
    },

    editItem(item) {
      this.spendingStream = Object.assign({}, item)
      this.editedId = item.id
      this.dialog = true
    },

    updateItem() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('updateSpendingStream', this.spendingStream)
          .then(() => {
            this.close()
          })
      }
    },

    deleteItem() {
      this.$store
        .dispatch('deleteSpendingStream', this.spendingStream)
        .then(() => {
          this.close()
        })
    },

    close() {
      this.dialog = false
      this.spendingStream = StaticDataService.getSpendingStream()
      this.$refs.form.resetValidation()
      this.editedId = -1
    },

    relatedAsset(id) {
      let relatedAsset = ''
      Object.entries(this.clientAssetsName).forEach(([, name]) => {
        if (id == name.value) {
          relatedAsset = name.text
        }
      })
      return relatedAsset
    }
  },

  computed: {
    formTitle() {
      return this.editedId === -1 ? 'New Expense' : 'Edit Expense'
    },

    // related Assets - class related
    // optionsName() {
    //   let options = []
    //   Object.entries(this.clientAssetsName).forEach(([, name]) => {
    //     if (name.class == this.spendingStream.class || name.class == 'None') {
    //       options.push(name)
    //     }
    //   })
    //   return options
    // },

    itemsCategory() {
      let items = []
      Object.entries(this.spendingCategoryItems).forEach(([, val]) => {
        if (this.spendingStream.class == val.class) {
          items = val.category
        }
      })
      return items
    },

    spendingCategoryItems() {
      let items = []
      this.spendingStreamClassItems.forEach(item => {
        let obj = { class: item, category: [] }
        let cat = []
        Object.entries(this.clientSpendingStreams).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.catText)
          }
        })
        Object.entries(this.adminDefinitionsSpending).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.category)
          }
        })
        Object.entries(this.advisorDefinitionsSpending).forEach(([, val]) => {
          if (val.class == obj.class) {
            cat.push(val.category)
          }
        })
        obj.category = Array.from(new Set(cat))
        items.push(obj)
      })
      return items
    },

    tableData() {
      let data = []
      let classes = []
      // get all active classes
      Object.entries(this.clientSpendingStreams).forEach(([, obj]) => {
        classes.push(obj.class)
      })
      let activeClasses = Array.from(new Set(classes))
      // build spending object data for drawer
      Object.entries(activeClasses).forEach(([, activeClass]) => {
        let sum = 0
        let spending = []
        Object.entries(this.clientSpendingStreams).forEach(([, obj]) => {
          if (activeClass == obj.class) {
            sum += obj.spendingPaTodayApp
            spending.push(obj)
          }
        })
        data.push({ class: activeClass, sum: sum, spending: spending })
      })
      data.sort(function(a, b) {
        return b.sum - a.sum
      })
      return data
    },

    getRelatedAssetProjectionIncludeValue() {
      let value
      Object.entries(this.clientAssetsName).forEach(([, obj]) => {
        if (obj.value == this.spendingStream.idRelated) {
          value = obj.projectionInclude
        }
      })
      return value
    },

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
