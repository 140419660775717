<template>
  <div>
    <v-card flat class="mx-11 mt-6">
      <v-card-text class="primary--text"
        >Click a ledger item/group to include or exclude the item/group in
        retirement - or cash flow projections
      </v-card-text>
    </v-card>
    <v-card flat outlined class="mx-12 mt-4">
      <template>
        <v-data-table
          :headers="headers"
          :items="clientLedgerGrouped"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page="100"
          hide-default-footer
          hide-default-header
          height="623px"
          class="title primary--text"
        >
          <template v-slot:top>
            <v-card flat color="white" class="text-center"> </v-card>
          </template>

          <template v-slot:item.projectionInclude="{ item }">
            <div v-if="item.ledger == 'Asset'">
              <v-checkbox
                v-model="item.projectionInclude"
                class="subtitle-1 primary--text mb-1 mt-0"
                hide-details
                disabled
              >
              </v-checkbox>
            </div>
            <div else>
              <div v-if="item.idRelated != -1 && item.ledger != 'Asset'">
                <v-checkbox
                  v-model="item.projectionInclude"
                  class="subtitle-1 primary--text mb-1 mt-0"
                  hide-details
                  disabled
                >
                </v-checkbox>
              </div>
              <div v-else-if="item.ledger != 'Asset'">
                <v-checkbox
                  v-model="item.projectionInclude"
                  class="subtitle-1 primary--text mb-1 mt-0"
                  hide-details
                  disabled
                >
                </v-checkbox>
              </div>
            </div>
          </template>

          <template v-slot:item.name="{ item }">
            <div v-if="item.ledger == 'Asset'">
              <v-btn
                text
                retain-focus-on-click
                color="success"
                class="text-capitalize ml-n4 my-n4"
                @click="editItem(item)"
                ><div v-if="item.class == 'Precious Metal'">
                  {{ item.name }}
                  {{
                    item.catText
                      ? ' / ' +
                        item.catText +
                        ' / ' +
                        (item.amount * item.unit).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        }) +
                        ' oz '
                      : ''
                  }}
                </div>
                <div v-else-if="item.class == 'Crypto Currency'">
                  {{ item.name }}
                  {{
                    item.catText ? ' / ' + item.amount + ' ' + item.catText : ''
                  }}
                </div>
                <div v-else>
                  {{ item.name }}
                  {{
                    item.catText
                      ? ' / ' + item.class + ' / ' + item.catText
                      : ' / ' + item.class
                  }}
                </div>
              </v-btn>
            </div>
            <div else>
              <div v-if="item.idRelated != -1 && item.ledger != 'Asset'">
                <span class="subtitle-1 transparent--text">----</span>
                {{ item.name }}
                {{
                  item.catText
                    ? ' / ' + item.class + ' / ' + item.catText
                    : ' / ' + item.class
                }}
              </div>
              <div v-else-if="item.ledger != 'Asset'">
                <v-btn
                  text
                  retain-focus-on-click
                  color="success"
                  class="text-capitalize ml-n4 my-n4"
                  @click="editItem(item)"
                >
                  {{ item.name }}
                  {{ item.catText ? ' / ' + item.catText : item.class }}
                </v-btn>
              </div>
            </div>
          </template>

          <template v-slot:item.itemValue="{ item }">
            <v-card-text class="my-n4">
              <div v-if="item.ledger == 'Asset'">
                {{
                  item.priceTodayApp.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }}
                <span class="subtitle-1 transparent--text">----</span>
              </div>
              <div v-else-if="item.ledger == 'Liability'">
                {{
                  item.balanceDueTodayApp.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }}
                <span class="subtitle-1 transparent--text">----</span>
              </div>
              <div v-else-if="item.ledger == 'Income'">
                {{
                  item.incomePaTodayApp.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }} p.a.
              </div>
              <div v-else-if="item.ledger == 'Expense'">
                {{
                  item.spendingPaTodayApp.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                }}
                {{ currencyApp }} p.a.
              </div>
            </v-card-text>
          </template>

          <template v-slot:no-data>
            <v-card flat>
              <v-card-text class="title font-weight-regular">
                There are no Items matching your choice ...
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </template>
    </v-card>

    <v-dialog persistent v-model="dialog" max-width="450px">
      <v-card>
        <v-card-title class="ml-3">
          <div v-if="ledgerItem.projectionInclude == true">
            <span class="headline mt-4">Exclude ledger item/group</span>
          </div>
          <div v-else>
            <span class="headline mt-4">Include ledger item/group</span>
          </div>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text mb-4" @click="close" text>Cancel</v-btn>
          <div v-if="ledgerItem.projectionInclude == true">
            <v-btn color="success" @click="updateItem" class="mr-4 mb-4" text
              >Exclude</v-btn
            >
          </div>
          <div v-else>
            <v-btn color="success" @click="updateItem" class="mr-4 mb-4" text
              >Include</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'

export default {
  name: 'clientLedgerGroups',

  data() {
    return {
      dialog: false,
      sortBy: ['index'],
      sortDesc: [false],
      ledgerItem: StaticDataService.getAsset(),
      headers: [
        {
          text: '',
          align: 'left',
          value: 'projectionInclude',
          width: 10,
          class: 'subtitle-1 primary--text',
          sortable: false
        },
        {
          text: 'Ledger item',
          align: 'left',
          value: 'name',
          width: 280,
          class: 'subtitle-1 font-weight-normal primary--text',
          sortable: false
        },
        {
          text: 'Ledger',
          align: 'left',
          value: 'ledger',
          width: 150,
          class: 'subtitle-1 font-weight-normal primary--text',
          sortable: false
        },
        {
          text: 'Value',
          align: 'right',
          value: 'itemValue',
          width: 160,
          class: 'subtitle-1 font-weight-normal primary--text pr-12',
          sortable: false
        }
      ]
    }
  },

  methods: {
    editItem(item) {
      this.ledgerItem = Object.assign({}, item)
      this.dialog = true
    },

    updateItem() {
      this.ledgerItem.projectionInclude = !this.ledgerItem.projectionInclude
      console.log('projectionInclude = ', this.ledgerItem.projectionInclude)
      if (this.ledgerItem.ledger == 'Asset') {
        this.$store.dispatch('updateAsset', this.ledgerItem).then(() => {
          this.close()
        })
      }
      if (this.ledgerItem.ledger == 'Liability') {
        this.$store.dispatch('updateLiability', this.ledgerItem).then(() => {
          this.close()
        })
      }
      if (this.ledgerItem.ledger == 'Income') {
        this.$store.dispatch('updateIncomeSource', this.ledgerItem).then(() => {
          this.close()
        })
      }
      if (this.ledgerItem.ledger == 'Expense') {
        this.$store
          .dispatch('updateSpendingStream', this.ledgerItem)
          .then(() => {
            this.close()
          })
      }
    },

    close() {
      this.dialog = false
      this.ledgerItem = StaticDataService.getAsset()
    }
  },

  computed: {
    ledgerName() {
      return this.ledgerItem.ledger
    },

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
