<template>
  <div>
    <v-card width="500" class="mt-5" flat>
      <v-card-title class="headline font-weight-normal primary--text">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <div class="text-center">
              Liabilities by Class (%)
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <div id="chart">
        <apexchart
          type="bar"
          height="550"
          :options="chartOptions"
          :series="liabilitiesByClassChartDataSorted"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'clientLedgerLiabilitiesChartClass',

  props: {
    liabilitiesByClassChartDataSorted: { type: Array }
  },
  data() {
    return {
      chartOptions: {
        colors: ['#f47d20'],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          type: 'numeric',
          labels: {
            formatter: function(value) {
              return value + ' %'
            }
          },
          forceNiceScale: false,
          max: this.maxYAxis()
        },
        xaxis: {
          type: 'category'
        },
        tooltip: {
          enabled: true
        }
      }
    }
  },
  methods: {
    maxYAxis() {
      // get index of highest number to display (Item at the end of array)
      let fLen = this.liabilitiesByClassChartDataSorted[0].data.length
      // get highest number to display on Y-Axis from Chart-Data
      let num = this.liabilitiesByClassChartDataSorted[0].data[fLen - 1].y
      // round highest number to display up (to the next 5, e.g. 27 to 30 or 24 to 25) to nicely scale the Y-Axis
      let maxYAxisNumber = this.roundUpNumber(num, 10)
      return parseInt(maxYAxisNumber)
    },
    roundUpNumber(num, precision) {
      num = parseFloat(num)
      if (!precision) return num.toLocaleString()
      return (Math.ceil(num / precision) * precision).toLocaleString()
    }
  },
  computed: {
    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
