<template>
  <div>
    <the-client-layout-header :headline="headline" />

    <v-card flat height="auto" class="d-flex align-stretch">
      <v-carousel
        v-model="caro"
        light
        height="auto"
        :show-arrows="false"
        hide-delimiters
        hide-delimiter-background
      >
        <v-carousel-item
          key="1"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="groups"
          class="mb-12"
        >
          <client-ledger-groups />
        </v-carousel-item>

        <v-carousel-item
          key="2"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="assets"
          class="mb-12"
        >
          <v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="8" lg="8">
              <client-ledger-assets :asset-class-items="assetClassItems" />
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <div v-if="assetsTotalByClassChartDataSorted[0].data.length > 1">
                <client-ledger-assets-total-chart-class
                  :assets-total-by-class-chart-data-sorted="
                    assetsTotalByClassChartDataSorted
                  "
                  :key="assetChartId"
                />
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="3"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="liabilities"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="8" lg="8">
              <client-ledger-liabilities
                :liability-class-items="liabilityClassItems"
              />
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <div v-if="liabilitiesByClassChartDataSorted[0].data.length > 1">
                <client-ledger-liabilities-chart-class
                  :liabilities-by-class-chart-data-sorted="
                    liabilitiesByClassChartDataSorted
                  "
                  :key="liabilityChartId"
                />
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="4"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="income"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="8" lg="8">
              <client-ledger-income
                :income-source-class-items="incomeSourceClassItems"
              />
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <div v-if="incomeByClassChartDataSorted[0].data.length > 1">
                <client-ledger-income-chart-class
                  :income-by-class-chart-data-sorted="
                    incomeByClassChartDataSorted
                  "
                  :key="incomeChartId"
                />
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="5"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="expenses"
          class="mb-12"
          ><v-row class="mx-12 mt-n8">
            <v-col cols="12" sm="12" md="8" lg="8">
              <client-ledger-spending
                :spending-stream-class-items="spendingStreamClassItems"
              />
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <div v-if="spendingByClassChartDataSorted[0].data.length > 1">
                <client-ledger-spending-chart-class
                  :spending-by-class-chart-data-sorted="
                    spendingByClassChartDataSorted
                  "
                  :key="spendingChartId"
                />
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          key="6"
          reverse-transition="fade-transition"
          transition="fade-transition"
          value="goals"
          class="mb-12"
        >
          <client-ledger-goals />
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'
import TheClientLayoutHeader from '@/components/TheClientLayoutHeader.vue'
import ClientLedgerGroups from '@/components/ClientLedgerGroups.vue'
import ClientLedgerAssets from '@/components/ClientLedgerAssets.vue'
import ClientLedgerAssetsTotalChartClass from '@/components/ClientLedgerAssetsTotalChartClass.vue'
import ClientLedgerLiabilities from '@/components/ClientLedgerLiabilities.vue'
import ClientLedgerLiabilitiesChartClass from '@/components/ClientLedgerLiabilitiesChartClass.vue'
import ClientLedgerIncome from '@/components/ClientLedgerIncome.vue'
import ClientLedgerIncomeChartClass from '@/components/ClientLedgerIncomeChartClass.vue'
import ClientLedgerSpending from '@/components/ClientLedgerSpending.vue'
import ClientLedgerSpendingChartClass from '@/components/ClientLedgerSpendingChartClass.vue'
import ClientLedgerGoals from '@/components/ClientLedgerGoals.vue'

export default {
  name: 'clientLedger',

  beforeRouteEnter(routeTo, routeFrom, next) {
    // making sure, when navigating to this page, the slider allways moves to "LEDGER" ( tab = 2 )
    store.commit('SET_CLIENT_LAYOUT_TAB', 2)
    store.commit('SET_CLIENT_LEDGER_CARO', routeTo.params.tabs)
    next()
  },

  components: {
    TheClientLayoutHeader,
    ClientLedgerGroups,
    ClientLedgerAssets,
    ClientLedgerAssetsTotalChartClass,
    ClientLedgerLiabilities,
    ClientLedgerLiabilitiesChartClass,
    ClientLedgerIncome,
    ClientLedgerIncomeChartClass,
    ClientLedgerSpending,
    ClientLedgerSpendingChartClass,
    ClientLedgerGoals
  },

  data() {
    return {
      assetChartId: 0,
      liabilityChartId: 0,
      incomeChartId: 0,
      spendingChartId: 0,
      ledger5: {
        groups: { id: 1, name: 'Groups' },
        assets: { id: 2, name: 'Assets' },
        liabilities: { id: 3, name: 'Liabilities' },
        income: { id: 4, name: 'Income' },
        expenses: { id: 5, name: 'Expenses' },
        goals: { id: 6, name: 'Goals' }
      }
    }
  },

  methods: {
    refreshAssetChart() {
      this.assetChartId += 1
    },
    refreshLiabilityChart() {
      this.liabilityChartId += 1
    },
    refreshIncomeChart() {
      this.incomeChartId += 1
    },
    refreshSpendingChart() {
      this.spendingChartId += 1
    }
  },

  computed: {
    headline() {
      return 'Ledger / ' + this.page.name
    },

    caro: {
      get() {
        return this.system.caroClientLedger
      },
      set(ledger) {
        this.$store.commit('SET_CLIENT_LEDGER_CARO', ledger)
        this.$router.push({ name: 'clientLedger', params: { tabs: ledger } })
      }
    },

    page() {
      let page = this.ledger5[this.caro]
      return page
    },

    // asset processing functions (classes and chart data)

    // create a set of all available asset classes (to be used in New Asset - select Class)
    assetClassItems() {
      let list = []
      Object.entries(this.clientAssets).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.adminDefinitionsAssets).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.advisorDefinitionsAssets).forEach(([, val]) => {
        list.push(val.class)
      })
      return Array.from(new Set(list)).sort()
    },

    // create Chart Data (Array of cummulative asset class values in %)
    assetsTotalByClassChartDataSorted() {
      let chartData = []
      let i, fLen
      // console.log('assetClassItems = ', this.assetClassItems)
      fLen = this.assetClassItems.length
      for (i = 0; i < fLen; i++) {
        let classPercent = 0
        let item = { x: '', y: 0 }
        Object.entries(this.clientAssets).forEach(([, asset]) => {
          if (asset.class == this.assetClassItems[i]) {
            classPercent += asset.priceTodayAppPercent
          }
        })
        item.y = Math.floor(classPercent * 10) / 10
        if (item.y >= 99.7) {
          item.y = 100
        }
        item.x = this.assetClassItems[i]
        // only add relevant classes ( y > 0% )
        if (item.y > 0) {
          chartData.push(item)
        }
      }
      chartData.sort(function(a, b) {
        return a.y - b.y
      })
      this.refreshAssetChart()
      // console.log('chartData = ', chartData)
      return [{ name: 'Asset', data: chartData }]
    },

    // liability processing functions (classes and chart data)
    liabilityClassItems() {
      let list = []
      Object.entries(this.clientLiabilities).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.adminDefinitionsLiabilities).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.advisorDefinitionsLiabilities).forEach(([, val]) => {
        list.push(val.class)
      })
      return Array.from(new Set(list)).sort()
    },

    liabilitiesByClassChartDataSorted() {
      let chartData = []
      let i, fLen
      fLen = this.liabilityClassItems.length
      for (i = 0; i < fLen; i++) {
        let classPercent = 0
        let item = { x: '', y: 0 }
        Object.entries(this.clientLiabilities).forEach(([, liability]) => {
          if (liability.class == this.liabilityClassItems[i]) {
            classPercent += liability.balanceDueTodayAppPercent
          }
        })
        item.y = Math.floor(classPercent * 10) / 10
        if (item.y >= 99.7) {
          item.y = 100
        }
        item.x = this.liabilityClassItems[i]
        // only add relevant classes ( y > 0% )
        if (item.y > 0) {
          chartData.push(item)
        }
      }
      chartData.sort(function(a, b) {
        return a.y - b.y
      })
      this.refreshLiabilityChart()
      return [{ name: 'Liability', data: chartData }]
    },

    // income sources processing functions (classes and chart data)
    incomeSourceClassItems() {
      // create systemList
      let systemList = []
      let systemDefinitionsIncome = StaticDataService.getDefinitions()
        .definitions.incomeSources
      Object.entries(systemDefinitionsIncome).forEach(
        ([, incomeItemSystem]) => {
          systemList.push(incomeItemSystem.class)
        }
      )

      // create grossList (from all possible sources combined)
      let list = []
      Object.entries(this.clientIncomeSources).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.adminDefinitionsIncome).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.advisorDefinitionsIncome).forEach(([, val]) => {
        list.push(val.class)
      })
      // create Set from list array
      let grossList = Array.from(new Set(list))

      // reduce system items from grossList
      let netList = grossList.filter(
        ar => !systemDefinitionsIncome.find(rm => rm.class === ar)
      )

      // concat systemItems (ordered) with netList items (place always at the end of list)
      let result = systemList.concat(netList)
      return result
    },

    incomeByClassChartDataSorted() {
      let chartData = []
      let i, fLen
      fLen = this.incomeSourceClassItems.length
      for (i = 0; i < fLen; i++) {
        let classPercent = 0
        let item = { x: '', y: 0 }
        Object.entries(this.clientIncomeSources).forEach(([, incomeSource]) => {
          if (incomeSource.class == this.incomeSourceClassItems[i]) {
            classPercent += incomeSource.incomePaTodayAppPercent
          }
        })
        item.y = Math.floor(classPercent * 10) / 10
        if (item.y >= 99.7) {
          item.y = 100
        }
        item.x = this.incomeSourceClassItems[i]
        // only add relevant classes ( y > 0% )
        if (item.y > 0) {
          chartData.push(item)
        }
      }
      chartData.sort(function(a, b) {
        return a.y - b.y
      })
      this.refreshIncomeChart()
      return [{ name: 'Income', data: chartData }]
    },

    // spending streams processing functions (classes and chart data)
    spendingStreamClassItems() {
      let list = []
      Object.entries(this.clientSpendingStreams).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.adminDefinitionsSpending).forEach(([, val]) => {
        list.push(val.class)
      })
      Object.entries(this.advisorDefinitionsSpending).forEach(([, val]) => {
        list.push(val.class)
      })
      return Array.from(new Set(list)).sort()
    },

    spendingByClassChartDataSorted() {
      let chartData = []
      let i, fLen
      fLen = this.spendingStreamClassItems.length
      for (i = 0; i < fLen; i++) {
        let classPercent = 0
        let item = { x: '', y: 0 }
        Object.entries(this.clientSpendingStreams).forEach(
          ([, spendingStream]) => {
            if (spendingStream.class == this.spendingStreamClassItems[i]) {
              classPercent += spendingStream.spendingPaTodayAppPercent
            }
          }
        )
        item.y = Math.floor(classPercent * 10) / 10
        if (item.y >= 99.7) {
          item.y = 100
        }
        item.x = this.spendingStreamClassItems[i]
        // only add relevant classes ( y > 0% )
        if (item.y > 0) {
          chartData.push(item)
        }
      }
      chartData.sort(function(a, b) {
        return a.y - b.y
      })
      this.refreshSpendingChart()
      return [{ name: 'Spending', data: chartData }]
    },

    ...mapState(['system']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
